import * as Base from '../../../components/base';

import DiceSvg from '../../../assets/dice.inline.svg';
import LineGraySvg from '../../../assets/line-gray.inline.svg';
import PlusSvg from '../../../assets/plus.inline.svg';
import React from 'react';
import SeedSvg from '../../../assets/seed.inline.svg';
import styled from 'styled-components';

export const BgContainer = styled(Base.BgContainer)`
  padding-bottom: 6.3rem;

  @media ${props => props.theme.breakpoints.md} {
    text-align: center;
  }
`;

export const Title1 = styled(Base.Title1)`
  @media ${props => props.theme.breakpoints.md} {
    text-align: center;
  }
`;

export const HeadingBlock = styled.div`
  display: inline-block;
  height: auto;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 4rem;

  @media ${props => props.theme.breakpoints.md} {
    margin-bottom: 2.7rem;
  }
`;

export const Description = styled(Base.Text1)`
  margin-bottom: 5rem;

  @media ${props => props.theme.breakpoints.md} {
    margin-bottom: 8rem;
  }
`;

export const BoxContainer = styled.section`
  display: block;

  @media ${props => props.theme.breakpoints.md} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const Box = styled.div`
  width: 100%;
  padding: 3rem 2.5rem;
  border: 0.2rem solid ${props => props.theme.colors.meteor};
  margin-bottom: 4rem;
  overflow: hidden;
  text-align: left;

  @media ${props => props.theme.breakpoints.md} {
    flex-basis: calc((100% - 4rem) / 2);
    padding: 2.4rem 2.5rem;
    margin-bottom: 0;
  }
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BoxDescription = styled(Base.Text2)`
  padding-top: 3rem;

  @media ${props => props.theme.breakpoints.md} {
    padding-top: 1.2rem;
  }
`;

export const BoxToggle = styled.a`
  padding-left: 6rem;

  @media ${props => props.theme.breakpoints.md} {
    display: none;
  }
`;

export const PlusToggle = styled(PlusSvg)`
  width: auto;
  transform: rotateY(0deg) rotate(${props => (props.opened ? '45deg' : '0deg')});
  transition: transform 0.5s;
`;

export const BoxIcon = styled.div`
  display: none;
  width: auto;
  margin-bottom: 1.5rem;
  margin-top: 2.2rem;

  @media ${props => props.theme.breakpoints.md} {
    display: inline-block;
  }
`;

export const SeedBoxIcon = styled(BoxIcon)`
  margin-bottom: 1.4rem;
  margin-top: 1.2rem;
`;

export const Dice = styled(DiceSvg)`
  display: block;
  width: 4.2rem;
  height: 4.3rem;
`;

export const Seed = styled(SeedSvg)`
  display: block;
  width: 3.6rem;
  height: auto;
`;

export const LineLightGray = styled(LineGraySvg)`
  display: none;
  width: 10.8rem;
  height: auto;

  @media ${props => props.theme.breakpoints.md} {
    display: block;
    margin: 0 auto;
    margin-bottom: 3.5rem;
  }
`;

export const DiceIcon = () => (
  <BoxIcon>
    <Dice />
  </BoxIcon>
);

export const SeedIcon = () => (
  <SeedBoxIcon>
    <Seed />
  </SeedBoxIcon>
);

export default HeadingBlock;
